import MenuLateral from '../componentes/MenuLateral'
import PiePaginaFormulario from '../componentes/PiePaginaFormulario'
import BlogCom from '../componentes/BlogCom'
import WhatsappIcon from '../componentes/Whatsapp';

function Contacto() {
    return (
        <>
            <WhatsappIcon />
            <MenuLateral />
            <BlogCom/>
            <PiePaginaFormulario />
        </>
    )
}
export default Contacto