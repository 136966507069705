import React from 'react';
/* import { ElfsightWidget } from 'react-elfsight-widget'; */

function Whatsapp() {
 /*  return <ElfsightWidget widgetID="43f6364f-576b-4011-b140-97e7fda5230a" />; */
 return (
  <>
     <div class="fixed-tabs-Whats">
                <div class="aba-whatsphone">
                    <div class="aba-whatsphone-icone">
                        <div className='as'>
                            <a target="_blank" href="https://api.whatsapp.com/send?phone=593959792827">Let's travel <br/><br/> <strong>together</strong></a>
                        </div>
                    </div>
                </div>
            </div>
  </>

);  
}
export default Whatsapp;