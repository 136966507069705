const data = [
	{
		id: 1,
		image:
			'./img/blogs/Blog1Inicio.jpg',
		title: 'Importancia de ChatGPT enfocada al marketing',
		category: 'Inteligencia Artificial',
		description: 'ChatGPT mejora el marketing al interactuar personalizada y automatizadamente con los clientes',
		date: 'Abril 05, 2023',
		ReadingTime: '3 minutos',
	},
	{
		id: 2,
		image:
			'./img/blogs/Blog2.jpg',
		title: 'Paid Media VS Organic Media',
		category: 'Marketing Digital',
		description:
			'El blog destaca cómo las estrategias de Paid Media y Organic Media en redes sociales pueden ayudarte.',
		date: 'Abril 05, 2023',
		ReadingTime: '5 minutos',
		textBlog: 'En la actualidad, las redes sociales se han convertido en una herramienta esencial para las empresas que buscan aumentar su presencia en línea y alcanzar sus objetivos de negocio. Sin embargo, con la gran cantidad de contenido que se publica en estas plataformas cada día, resulta difícil destacar y ser notado por los usuarios. Por ello, es fundamental que las empresas utilicen estrategias de marketing en línea efectivas para lograr sus objetivos, y dos de estas estrategias son el Paid Media y el Organic Media.',
		textBlog2: 'El Paid Media, también conocido como publicidad pagada, es una estrategia de marketing digital que implica pagar por la exhibición de anuncios o contenido promocional en plataformas como Facebook, Instagram, LinkedIn y Twitter. Los anuncios pagados se utilizan para lograr objetivos de negocio específicos, como aumentar el reconocimiento de marca, incrementar el tráfico en el sitio web de la empresa, generar leads y aumentar las ventas.',
		textBlog3: 'Existen diferentes tipos de anuncios pagados que se pueden utilizar en las redes sociales. Por ejemplo, en Facebook e Instagram, se pueden utilizar anuncios de imagen, video, carrusel, colección y anuncios en stories. Además, las plataformas ofrecen diferentes opciones de segmentación que permiten a las empresas dirigirse a usuarios específicos, por ejemplo, por edad, ubicación, intereses, comportamientos y más.',
		textBlog4: 'La ventaja de utilizar Paid Media es que las empresas pueden tener un control total sobre la distribución de sus anuncios y la audiencia a la que quieren llegar. Además, pueden medir con precisión los resultados de sus campañas, ya que las plataformas ofrecen estadísticas detalladas sobre el rendimiento de los anuncios, como el alcance, las impresiones, los clics, los costos y las conversiones.',
		textBlog5: 'Por otro lado, el Organic Media es una estrategia de marketing digital que se basa en la creación y difusión de contenido gratuito en las redes sociales. A diferencia del Paid Media, el Organic Media no requiere un presupuesto para la promoción de publicaciones. En su lugar, las empresas pueden utilizar técnicas de SEO y marketing de contenido para lograr objetivos de negocio como aumentar su visibilidad en línea y llegar a más personas.',
		textBlog6: 'El contenido orgánico se refiere a todo el contenido gratuito que se comparte en las redes sociales, incluyendo publicaciones en Facebook e Instagram, videos en YouTube, artículos de blog y más. Al compartir contenido de calidad y relevante, las empresas pueden atraer a más usuarios a sus páginas, aumentar su base de seguidores y mejorar su presencia en línea.',
		textBlog7: 'Una de las principales ventajas de utilizar Organic Media es que es una estrategia más económica que el Paid Media, lo que significa que es más accesible para empresas que tienen un presupuesto limitado para su marketing digital. Además, el contenido orgánico es un recurso valioso para lograr objetivos de negocio como generar engagement con la audiencia, lo que puede generar fidelidad y lealtad a la marca.',
		textBlog8: 'Sin embargo, es importante tener en cuenta que el Organic Media también puede requerir más tiempo y esfuerzo para generar resultados efectivos. A diferencia de los anuncios pagados, el contenido orgánico no ofrece la misma garantía de llegar a un público objetivo específico.'
	}
];

export default data;